<template>
  <div class="course comprehensiveCourse">
    <TabPageLayout activeCode="yizhongkecheng">
      <div class="container schoolCourse">
        <div class="div_tit">
          <h2>{{ currentType?.title }}</h2>
        </div>
        <div v-html="currentType?.bz"></div>
        <div class="div_table">
          <table>
            <tr>
              <th>课程</th>
              <th>适用学生</th>
              <th>课程类型</th>
            </tr>
            <tr v-for="article in articles" :key="article.id">
              <td>{{ article.title }}</td>
              <td>{{ article.des }}</td>
              <td>{{ article.tagname }}</td>
            </tr>
          </table>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import portalApi from '../../../lvya-common/src/api/eos/portal'
import TabPageLayout from './components/TabPageLayout.vue'
import { mapState } from 'vuex'
import { useAddColumnCount } from './utils/useAddArticleCount'
export default {
  components: {
    TabPageLayout,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
      courseTypes: (state) => state.yzsyStore.courseTypes,
    }),
    typeId() {
      return this.$route.query.typeId
    },
    currentType() {
      return this.courseTypes?.find(
        (item) => item.id == this.$route.query.typeId
      )
    },
  },
  data() {
    return {
      articles: [],
    }
  },
  methods: {
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: 1,
        pageSize: 999,
        typeId: this.typeId,
        isContent: 'Y',
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      this.articles = resData.list
    },
  },
  mounted() {
    this.getArticles()
    useAddColumnCount(this.typeId)
  },
}
</script>

<style lang="less" scoped>
@import "./style/course.css";
@import "./style/common.css";
@import "./style/global.css";
</style>
